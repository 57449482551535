//--------------------------------------------------
//GSS Randomizer
//--------------------------------------------------

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';

import music from '../../audio/wanted.mp3'
import soundEffect from '../../audio/dumbvictory.wav'

import balthazar from '../../Images/GSS/balthazar.jpg'
import blueka from '../../Images/GSS/blueka.jpg'
import browi from '../../Images/GSS/browi.jpg'
import chii from '../../Images/GSS/chii.jpg'
import cowboy from '../../Images/GSS/cowboy.jpg'
import diggle from '../../Images/GSS/diggle.jpg'
import diego from '../../Images/GSS/diego.jpg'
import envii from '../../Images/GSS/envii.jpg'
import ezpz from '../../Images/GSS/ezpz.jpg'
import flowers from '../../Images/GSS/flowers.jpg'
import gokies from '../../Images/GSS/gokies.jpg'
import jmuze from '../../Images/GSS/jmuze.jpg'
import lasersword from '../../Images/GSS/lasersword.jpg'
import leiadog from '../../Images/GSS/leiadog.jpg'
import mada from '../../Images/GSS/mada.jpg'
import marathon from '../../Images/GSS/marathon.jpg'
import melfor from '../../Images/GSS/melfor.jpg'
import mongo from '../../Images/GSS/mongo.jpg'
import opz from '../../Images/GSS/opz.PNG'
import pladman from '../../Images/GSS/pladman.jpg'
import pow from '../../Images/GSS/pow.jpg'
import relwrd from '../../Images/GSS/relwrd.jpg'
import soupdude from '../../Images/GSS/soupdude.jpg'
import waga from '../../Images/GSS/waga.jpg'
import yata from '../../Images/GSS/yata.jpg'

const GSSRandomizer = ({ }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isSpinning, setIsSpinning] = useState(true);
    const audioRef = useRef(null);
    const soundEffectRef = useRef(null);

    const GSS = [
        {image: balthazar, name: "Balthazar", number: "0"},
        {image: blueka, name: "blue cuh", number: "2089602959"},
        {image: browi, name: "Browi", number:"2089222008"},
        {image: chii, name: "chii", number: "9497505302"},
        {image: cowboy, name: "Cowboy", number: "0"},
        {image: diego, name: "DiegoHD", number: "0"},
        {image: diggle, name: "DiGgLe", number: "5712712485"},
        {image: envii, name: "Envii", number: "2085091821"},
        {image: ezpz, name: "EZPZ", number: "2088007902"},
        {image: flowers, name: "flowers", number: "0"},
        {image: gokies, name: "Gokies", number: "0"},
        {image: jmuze, name: "Jmuze", number: "5306468348"},
        {image: lasersword, name: "LaserSword", number: "2085018139"},
        {image: leiadog, name: "leiadog", number: "2085078846"},
        {image: mada, name: "Mada", number: "9862138164"},
        {image: marathon, name: "Marathon", number: "0"},
        {image: melfor, name: "MELFOR", number: "2089140308"},
        {image: mongo, name: "Mongo", number: "0"},
        {image: opz, name: "OpZ", number: "2088076225"},
        {image: pladman, name: "PladMan", number: "2089856295"},
        {image: pow, name: "Pow", number: "2085705128"},
        {image: relwrd, name: "Relwrd", number: "2087137563"},
        {image: soupdude, name: "SoupDude", number: "2535799325"},
        {image: waga, name: "Waga", number: "0"},
        {image: yata, name: "Yata GAMING", number: "2088911641"},
    ]

    useEffect(() => {
        let interval;
        if (isSpinning) {
          interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % GSS.length);
          }, 100); // Change every 100ms
          if (audioRef.current) {
            audioRef.current.play().catch((error) => console.error('Music autoplay blocked:', error));
          }
        } else {
          if (audioRef.current) audioRef.current.pause();
        }
        return () => clearInterval(interval);
      }, [isSpinning]);
    
      const handleButtonClick = () => {
        if (isSpinning) {
          setIsSpinning(false);
          if (soundEffectRef.current) {
            soundEffectRef.current.play();
          }
        } else {
          setIsSpinning(true);
          if (audioRef.current) {
            audioRef.current.play();
          }
        }
      };

    return (
        <div className="gss-randomizer">
            <div className="randomizer-display">
                <img src={GSS[currentIndex].image} alt={GSS[currentIndex].name} />
                <h2>{GSS[currentIndex].name}</h2>
            </div>
        <button className='stop-button' onClick={handleButtonClick}>
            {isSpinning ? 'Stop' : 'Spin'}
        </button>
            <audio ref={audioRef} src={music} loop />
            <audio ref={soundEffectRef} src={soundEffect} />
        {isSpinning ? 
            <></>
            : 
            <div className='call'>
                <h2>Would you like to call them?</h2>
                {GSS[currentIndex].number !== "0" ? 
                    <a href={`tel:+1${GSS[currentIndex].number}`}>
                        <button>CALL</button>
                    </a>
                :
                    <h2>No phone number available...</h2>
                }
            </div>
        }
        </div>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(GSSRandomizer);
export { connection as GSSRandomizer };