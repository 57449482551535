//--------------------------------------------------
//Root logic for client rendering down from index.js
//--------------------------------------------------

import React, { Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Home, GSSRandomizer } from '../Components/UserPages';
import { PageNotFound } from '../Components/GeneralComponents';

const App = ({ alert, session }) => {  
  const aboutRef = useRef(null);
  const projectRef = useRef(null);
  const resumeRef = useRef(null);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home aboutRef={aboutRef} projectRef={projectRef} resumeRef={resumeRef} />} />
        <Route path='*' element={<PageNotFound />} />
        <Route path='/gss' element={<GSSRandomizer />} />
      </Routes>
    </BrowserRouter>
  )
}

function mapState(state) {
  const { alert, session } = state
  return { alert, session };
}

const actionCreators = {

};

export default connect(mapState, actionCreators)(App);