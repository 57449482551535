//--------------------------------------------------
//Rendered Component when opening website for the first time
//--------------------------------------------------

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { ProjectModal, Sidebar, NavigationBar } from '../GeneralComponents';
import { Container, Row, Col } from 'react-bootstrap';

import otherBubble from '../../Images/fun-small-thing.svg'
import leftButton from '../../Images/left-button.svg'
import rightButton from '../../Images/right-button.svg'
import TVWH from '../../Images/TVWH.PNG'
import FATE from '../../Images/FATE.png'
import Agent13 from '../../Images/Agent13.PNG'
import MINUS from '../../Images/MINUS.png'
import resume from '../../Files/JC_Ho_Resume.pdf'

// TEMPLATE
// { name: '', img: blank, description: '', role: '', date: '' },

let projects = [
    { name: 'Treasure Valley Water Hub', img: TVWH, description: 'A React website created for Immerse.', role: 'Web Developer', date: '8/15/24'},
    { name: 'F.A.T.E.', img: FATE, description: "A React website created for teachers to rate other academic professional's portfolios.", role: 'Temporary Web Developer', date: '6/6/24' },
    { name: 'Agent 13', img: Agent13, description: 'A Unity VR Action/Stealth game.', role: 'Game Developer', date: '5/3/24' },
    { name: 'ULT MINUS', img: MINUS, description: 'A casual competitive mod created by me for Smash Ultimate.', role: 'Dev Lead', date: 'WIP' },
]

const Home = ({ aboutRef, projectRef, resumeRef }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState({ name: '', image: null });
  
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    const handleSlideClick = (project) => {
        setSelectedProject(project);
        setShowModal(true);
      };
    
      const handleClose = () => {
        setShowModal(false);
      };

    return (
        <Fragment>
            <NavigationBar scrollToRef={scrollToRef} aboutRef={aboutRef} projectRef={projectRef} resumeRef={resumeRef} />
            <Sidebar scrollToRef={scrollToRef} aboutRef={aboutRef} projectRef={projectRef} resumeRef={resumeRef} />
            <Container fluid className='home'> 
                { /* ABOUT SECTION */ }
                <Row className='justify-content-center about-section' ref={aboutRef}>
                    <Col className='text-bubble' md={5}>
                        <h1>About Me</h1>
                        <p>
                        Hello! I am JC Ho! I am a Senior at Boise State University currently enrolled in the Games, Interactive Media, and Mobile program/major. My skills are mainly in Web Development and Software Development. I have made a few projects in React and hope to continue growing my Web Development skills. I also have developed a few games and also have made my own mods. I really enjoy creating software and hope to have a future in it where I can make things truly impactful and things that can help. I always look for chances to improve myself and my portfolio, so I hope to see this website grow as I do!
                        </p>
                    </Col>
                    <Col xs md={3}>
                        <img className='bubble1' src={otherBubble}></img>
                    </Col>
                    <Col xs md={3}>
                        <img className='bubble2' src={otherBubble}></img>
                    </Col>
                </Row>
                
                { /* PROJECT SECTION */ }
                <Row className='justify-content-center project-section' ref={projectRef}>
                    <h1>Projects</h1>
                    <Swiper 
                        className='project-swiper'
                        modules={[Navigation]}
                        navigation={{
                            nextEl: '.next',
                            prevEl: '.prev',
                        }} 
                        slidesPerView={2}
                        setWrapperSize={false}
                        spaceBetween={50}
                        breakpoints={{
                            320: {
                              slidesPerView: 1,
                              spaceBetween: 35,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 50,
                            },
                          }}>

                        {projects.map((project, index) => (
                            <SwiperSlide key={index} className='slide' onClick={() => handleSlideClick(project)}>
                            <h1 className='project-title'>{project.name}</h1>
                            <img src={project.img} />
                            <p className='description'>{project.description}</p>
                            <div className='extra-details'>
                                <p>Role: <br/> {project.role}</p>
                                <p>Date Completed: <br/> {project.date}</p>
                            </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className='swiper-buttons'>
                        <img className="prev" src={leftButton}></img>
                        <img className="next" src={rightButton}></img>
                    </div>

                    {showModal && (
                        <ProjectModal
                        name={selectedProject.name}
                        image={selectedProject.img}
                        show={showModal}
                        handleClose={handleClose}
                        />
                    )}
                </Row>

                { /* RESUME SECTION */ }
                <Row className='justify-content-center resume-section' ref={resumeRef}>
                    <h1>Resume</h1>
                    <iframe class='resume' src={resume}></iframe>
                </Row>
                <br/>
            </Container>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Home);
export { connection as Home };